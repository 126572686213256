import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import SocialReviews from "../components/SocialReviews/SocialReviews"
import { graphql } from "gatsby"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

const ContactUsPage = props => {
  var pageContext = props.pageContext
  var location = props.location
  var data = props.data
  var post
  var language

  if (pageContext && pageContext.language) {
    language = pageContext.language
    switch (language) {
      case "en":
        post = data.allUniquePagesJson.nodes[0]
        break
      case "es":
        post = data.allSpanishUniquePagesJson.nodes[0]
        break
      default:
        post = data.allUniquePagesJson.nodes[0]
    }
  } else {
    post = data.allUniquePagesJson.nodes[0]
  }

  var locationHours =
    data.allDataJson.nodes[0].siteItems.locations[0].location.locationHours

  let locationOne = data.allDataJson.nodes[0].siteItems.locations[1].location
  let locationTwo = data.allDataJson.nodes[0].siteItems.locations[0].location

  const telLink = phone => "tel:+" + phone.replace(/[^+\d]+/g, "")

  return (
    <SharedStateProvider>
      <Layout layoutClass={"contact-fab"} language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <span className="menu-spacer"></span>

        <div className="contact-top-wrapper">
          <div className="cls__contact-us">
            <ImageMeta
              className="splash"
              cloudName="nuvolum"
              publicId={post.googleMapsBackground.splashPublicId}
              width="auto"
            ></ImageMeta>
          </div>

          {/* <a href={post.googleMapsBackground.pinLink} target="_blank">
          <div className="marker-heading">
            <img className="marker" src={locationMarker} alt="Location Marker" />
            <h5>
            {post.googleMapsBackground.pinTextLineOne}
              <br />
              {post.googleMapsBackground.pinTextLineTwo}
            </h5>
          </div>
</a> */}
          <div className="contact-top-headings">
            <h1>{post.heading}</h1>
          </div>
        </div>

        <div className="body-section contact-us-body">
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column is-10">
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(
                    post.contactBody.heading + post.contactBody.blurb
                  )
                )}
              ></div>
              {language !== "es" && (
                <div className="contact-form-section">
                  <div className="inline-block">
                    <h5>Send Us a Message</h5>{" "}
                    <span className="required-fields">
                      <span className="required-asterisk">*</span> Required
                      Fields
                    </span>
                  </div>
                  <Seamless src="https://secureform.seamlessdocs.com/f/b56f00bdf17d1fb3e669e646ad3d7152?embedded=true" />
                </div>
              )}
            </div>
            <div className="column is-1"></div>
            <div className="column is-narrow contact-info is-hidden-mobile">
              <div className="contact-sidebar-block">
                <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                <a href={locationOne.googlePlaceLink} target="_blank">
                  {locationOne.streetNumber + " " + locationOne.streetName}
                  <br />
                  {`${locationOne.cityName}, ${locationOne.stateAbbr} ${locationOne.cityZip}`}
                </a>
              </div>
              <div className="contact-sidebar-block">
                <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                <a href={telLink(locationOne.contactInfo.phone)}>
                  {locationOne.contactInfo.phone}
                </a>
              </div>

              <div className="contact-sidebar-block">
                <GeneralHours
                  language={language}
                  contact={true}
                  locationHours={locationOne.locationHours}
                />
              </div>

              <br />

              <div className="contact-sidebar-block">
                <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                <a href={locationTwo.googlePlaceLink} target="_blank">
                  {locationTwo.streetNumber + " " + locationTwo.streetName}
                  <br />
                  {`${locationTwo.cityName}, ${locationTwo.stateAbbr} ${locationTwo.cityZip}`}
                </a>
              </div>
              <div className="contact-sidebar-block">
                <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                <a href={telLink(locationTwo.contactInfo.phone)}>
                  {locationTwo.contactInfo.phone}
                </a>
              </div>

              <div className="contact-sidebar-block">
                <GeneralHours
                  language={language}
                  contact={true}
                  locationHours={locationTwo.locationHours}
                />
              </div>
              <br />
              <div className="contact-reviews">
                <SocialReviews iconless language={language} />
              </div>
            </div>
            <div className="column is-5"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    allDataJson(filter: { optionsItem: { eq: "siteVariables" } }) {
      nodes {
        siteItems {
          locations {
            location {
              locationName
              stateAbbr
              googlePlaceLink
              streetNumber
              streetName
              cityZip
              cityName
              stateName
              locationHours {
                weekDay
                startTime
                endTime
              }
              contactInfo {
                phone
                email
              }
            }
          }
        }
      }
    }
  }
`

export default ContactUsPage
